Contact = {
    init: function() {
        //this.initMap();
    },
    initMap: function() {

		// Specify features and elements to define styles.
		var styleArray = [{"featureType":"water","elementType":"geometry.fill","stylers":[{"color":"#d3d3d3"}]},{"featureType":"transit","stylers":[{"color":"#808080"},{"visibility":"off"}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"visibility":"on"},{"color":"#b3b3b3"}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"road.local","elementType":"geometry.fill","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"weight":1.8}]},{"featureType":"road.local","elementType":"geometry.stroke","stylers":[{"color":"#d7d7d7"}]},{"featureType":"poi","elementType":"geometry.fill","stylers":[{"visibility":"on"},{"color":"#ebebeb"}]},{"featureType":"administrative","elementType":"geometry","stylers":[{"color":"#a7a7a7"}]},{"featureType":"road.arterial","elementType":"geometry.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"road.arterial","elementType":"geometry.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"landscape","elementType":"geometry.fill","stylers":[{"visibility":"on"},{"color":"#efefef"}]},{"featureType":"road","elementType":"labels.text.fill","stylers":[{"color":"#696969"}]},{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"visibility":"on"},{"color":"#737373"}]},{"featureType":"poi","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"poi","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"road.arterial","elementType":"geometry.stroke","stylers":[{"color":"#d6d6d6"}]},{"featureType":"road","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{},{"featureType":"poi","elementType":"geometry.fill","stylers":[{"color":"#dadada"}]}];

		var myLatLng = {lat: 59.420242, lng: 24.744879};

		//var myIcon = new google.maps.MarkerImage(siteUrl+'/dist/images/pin@2x.png', null, null, null, new google.maps.Size(99,99));

		var myIcon = {
		  url: siteUrl+'/dist/images/pin@2x.png',
		  size: new google.maps.Size(198, 198),
		  scaledSize: new google.maps.Size(99, 99),
		  origin: new google.maps.Point(0, 0),
		  anchor: new google.maps.Point(45, 45)
		};

		// Create a map object and specify the DOM element for display.
		var map = new google.maps.Map(document.getElementById('map'), {
		    scrollwheel: false,
		    center: myLatLng,
		    styles: styleArray,
		    zoom: 17,
		    disableDefaultUI: true, // a way to quickly hide all controls
		    mapTypeControl: false,
		    scaleControl: false,
		    zoomControl: false,
		    draggable: !("ontouchend" in document)
		});

		var marker = new google.maps.Marker({
		    position: myLatLng,
		    map: map,
		    icon: myIcon
		});
    }
};