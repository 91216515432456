Home = {
    init: function() {
        this.initCarousels();
    },
    initCarousels: function() {
    	var slider = $('.slider-for');

		slider.slick({
			slidesToShow: 1,
			slidesToScroll: 1,
		 	arrows: false,
			fade: false,
			autoplay: true,
  			autoplaySpeed: 8000,
  			pauseOnHover: false,
  			// centerMode: true,
  			// centerPadding: '30%',
  			// responsive: [
			  //   {
			  //     breakpoint: 768,
			  //     settings: {
			  //     	infinite: true,
			  //     	slidesToShow: 1,
			  //     	centerPadding: '0'
			  //     }
			  //   },
			  //   {
			  //     breakpoint: 992,
			  //     settings: {
			  //     	infinite: true,
			  //     	slidesToShow: 1,
			  //     	centerPadding: '0'
			  //     }
			  //   }
		   //  ]
		});

		$('.slick-next').on('click', function() {
			slider.slick("slickNext");
		});

		$('.slick-prev').on('click', function() {
			slider.slick("slickPrev");
		});

    }
};