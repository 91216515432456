Common = {
    init: function() {
        this.initOverlayMenu();
        this.initGA();
        this.backToTop();
        this.initScroller();
        this.initMobileContact();
        this.scrollDown();
    },
    initMobileContact: function() {
    	$('#mobile-contact').click(function () {
    		$('#menuModal').modal('hide');

    		setTimeout(function() {
		    	$('html, body').animate({
			        scrollTop: $("#contact").offset().top
			    }, 1000);
		    }, 500);
    	});
    },
    initScroller: function() {
		$('a.scroller[href*="#"]:not([href="#"])').click(function() {
			if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
			  var target = $(this.hash);
			  target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
			  if (target.length) {
			    $('html, body').animate({
			      scrollTop: target.offset().top
			    }, 1000);
			    return false;
			  }
			}
		});
    },
    initOverlayMenu: function() {

    	$(document).on('show.bs.modal', '.modal', function () {
		    var zIndex = 1040 + (10 * $('.modal:visible').length);
		    $(this).css('z-index', zIndex);
		    setTimeout(function() {
		        $('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack');
		    }, 0);
		});

		$(document).on('hidden.bs.modal', '#menuModal', function () {
			$(".js-toggle-menu").find('span').removeClass('btn-close');
		});

		$(".js-toggle-menu").click(function () {
	       // $(".overlay").fadeToggle(200);
	        $(this).find('span').toggleClass('btn-open').toggleClass('btn-open btn-close');
	    });

    },
    initGA: function() {
    	$('.js-search').click(function () {
	    	ga('send', {
	        	hitType: 'event',
	        	eventCategory: 'Desktop Otsing',
	        	eventAction: 'klikk',
	         	eventLabel: 'Otsi ikoon'
	        });
        });
    	$('.mobile-search__btn').click(function () {
	    	ga('send', {
	        	hitType: 'event',
	        	eventCategory: 'Mobiili Otsing',
	        	eventAction: 'klikk',
	         	eventLabel: 'Otsi nupp'
	        });
        });
        $('.search-open').click(function () {
	    	ga('send', {
	        	hitType: 'event',
	        	eventCategory: 'Otsing',
	        	eventAction: 'klikk',
	         	eventLabel: 'Otsingu ikoon'
	        });
        });
    	$('.js-toggle-menu').click(function () {
    		ga('send', {
	        	hitType: 'event',
	        	eventCategory: 'Menüü',
	        	eventAction: 'klikk',
	         	eventLabel: 'Menüü ikoon'
	        });
        });
    	$('.slider-home .slick-next').click(function () {
    		ga('send', {
	        	hitType: 'event',
	        	eventCategory: 'Slaider',
	        	eventAction: 'klikk',
	         	eventLabel: 'Next'
	        });
    	});
    	$('.slider-home .slick-prev').click(function () {
    		ga('send', {
	        	hitType: 'event',
	        	eventCategory: 'Slaider',
	        	eventAction: 'klikk',
	         	eventLabel: 'Prev'
	        });
    	});
    	$('.footer__cta').click(function () {
    		ga('send', {
	        	hitType: 'event',
	        	eventCategory: 'Footer',
	        	eventAction: 'klikk',
	         	eventLabel: 'View all cases'
	        });
    	});
    	$('.share__link').click(function () {
    		ga('send', {
	        	hitType: 'event',
	        	eventCategory: 'Share',
	        	eventAction: 'klikk',
	         	eventLabel: $(this).text()
	        });
    	});

    },
    backToTop: function() {
    	$('#back-to-top').on('click', function (e) {
	        e.preventDefault();
	        $('html,body').animate({
	            scrollTop: 0
	        }, 700);
	    });
    },
    scrollDown: function() {

		function GetURLParameter(sParam) {
			var sPageURL = window.location.search.substring(1);
			var sURLVariables = sPageURL.split('&');
			for (var i = 0; i < sURLVariables.length; i++) {
			      var sParameterName = sURLVariables[i].split('=');
			      if (sParameterName[0] == sParam) {
			          return sParameterName[1];
			      }
			}
		}

		function scrollToElement(selector, time, verticalOffset) {
			time = typeof(time) != 'undefined' ? time : 700;
			verticalOffset = typeof(verticalOffset) != 'undefined' ? verticalOffset : 0;
			element = $(selector);
			offset = element.offset();
			offsetTop = offset.top + verticalOffset;
			$('html, body').animate({
			    scrollTop: offsetTop
			}, time);
		}

    	$(window).load(function() {
		    var scroller = true;
			var scrollTo = GetURLParameter('open');

			if (scrollTo != undefined) {

		    	$.fn.almDone = function(alm){
		    		scroller = false;

		    		return scroller;
				};

				scroller = $.fn.almDone();

				if (scroller === false) {
					setTimeout(function() {
				    	scrollToElement('#contact', 1000, 0);
				    }, 100);
				}
			}
    	});

    }

};